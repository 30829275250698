import { createRouter, createWebHistory } from "vue-router";

export const routes = [
  {
    path: "/:companyId?",
    name: "Home",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/services/:service/:companyId?",
        name: "Services",
        component: () => import("../views/Blank.vue"),
      },
      {
        path: "/availability/:service/:companyId?",
        name: "Availability",
        component: () => import("../views/availability/Availability.vue"),
      },
      {
        path: "/modem/:type/:id?/:companyId?",
        name: "Modem",
        component: () => import("../views/Modem.vue"),
      },
      {
        path: "/acu/:id/:companyId?",
        name: "ACU",
        component: () => import("../views/devices/ACU.vue"),
      },
      {
        path: "/ipinterface/:id/:companyId?",
        name: "IPInterface",
        component: () => import("../views/devices/IPInterface.vue"),
      },
      {
        path: "/ipsla/:id/:companyId?",
        name: "IPSLA",
        component: () => import("../views/devices/IPSLA.vue"),
      },
      {
        path: "/router/:id/:companyId?",
        name: "Router",
        component: () => import("../views/devices/Router.vue"),
      },
      {
        path: "/switch/:id/:companyId?",
        name: "Switch",
        component: () => import("../views/devices/Switch.vue"),
      },
      {
        path: "/wanaccelerator/:id/:companyId?",
        name: "WANAccelerator",
        component: () => import("../views/devices/WANAccelerator.vue"),
      },
      {
        path: "/device/:id/:companyId?",
        name: "Device",
        component: () => import("../views/devices/Device.vue"),
      },
    ],
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: () => import("../views/ChangePassword.vue"),
  },
  {
    path: "/performance/:companyId?",
    name: "Performance",
    component: () => import("../views/Performance.vue"),
  },
  {
    path: "/testgraph",
    name: "Testgraph",
    component: () => import("../views/Testgraph.vue"),
  },
  {
    path: "/reports",
    name: "Reports",
    component: () => import("../views/reports/Reports.vue"),
    children: [
      {
        path: "/reports/servicesoverview/:companyId?",
        name: "ServicesOverview",
        component: () => import("../views/reports/ServicesOverview.vue"),
      },
      {
        path: "/reports/ipslasoverview/:companyId?",
        name: "IPSLAsOverview",
        component: () => import("../views/reports/IPSLAsOverview.vue"),
      },
      {
        path: "/reports/slasoverview/:companyId?",
        name: "SLAsOverview",
        component: () => import("../views/reports/SLAsOverview.vue"),
      },
      {
        path: "/reports/slalink/:linkId",
        name: "SLALinkDetails",
        component: () => import("../views/reports/SLALinkDetails.vue"),
      },
      {
        path: "/reports/starlink/:companyId?",
        name: "StarlinkReports",
        component: () => import("../views/reports/StarlinkReports.vue"),
        children: [
          {
            path: "/reports/starlink/usage/:companyId?",
            name: "StarlinkUsageReport",
            component: () => import("../views/reports/StarlinkUsage.vue"),
          },
          {
            path: "/reports/starlink/alerts/:companyId?",
            name: "StarlinkAlertsReport",
            component: () => import("../views/reports/StarlinkAlerts.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/support/:companyId?",
    name: "Support",
    component: () => import("../views/tickets/Support.vue"),
    children: [
      {
        path: "/support/cases/:companyId?",
        name: "Cases",
        component: () => import("../views/tickets/Tickets.vue"),
      },
      {
        path: "/support/cases/view/:number/:companyId?",
        name: "Case",
        component: () => import("../views/tickets/Tickets.vue"),
      },
      {
        path: "/support/change-requests/:companyId?",
        name: "ChangeRequests",
        component: () => import("../views/changeRequests/ChangeRequests.vue"),
      },
      {
        path: "/support/change-requests/view/:number/:companyId?",
        name: "ChangeRequest",
        component: () => import("../views/changeRequests/ChangeRequests.vue"),
      },
    ],
  },
  {
    path: "/billing/:companyId?",
    name: "Billing",
    component: () => import("../views/billing/Billing.vue"),
    children: [
      {
        path: "/billing/invoices/:companyId?",
        name: "Invoices",
        component: () => import("../views/billing/Invoices.vue"),
      },
    ],
  },
  {
    path: "/starlink/:companyId?",
    name: "Starlink",
    component: () => import("../views/starlink/Starlink.vue"),
    children: [
      {
        path: "/starlink/activatekits/:companyId?",
        name: "StarlinkActivateKits",
        component: () => import("../views/starlink/activation/ActivateKits.vue"),
      },
      {
        path: "/starlink/activatekitssummary/:companyId?",
        name: "StarlinkActivateKitsSummary",
        component: () => import("../views/starlink/activation/ActivateKitsSummary.vue"),
      },
      {
        path: "/starlink/editserviceplan/:companyId?",
        name: "StarlinkEditServicePlan",
        component: () => import("../views/starlink/planadmin/EditServicePlan.vue"),
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/admin/Admin.vue"),
    children: [
      {
        path: "/admin/roles/:companyId?",
        name: "AdminRoles",
        component: () => import("../views/admin/roles/Roles.vue"),
      },
      {
        path: "/admin/alerts/:companyId?",
        name: "AdminAlerts",
        component: () => import("../views/admin/alerts/StarlinkMonitors.vue"),
      },
      {
        path: "/admin/user-roles/:userId",
        name: "UserRoles",
        component: () => import("../views/admin/roles/UserRoles.vue"),
      },
    ],
  },
  //   {
  //   path: "/tickets/:companyId?",
  //   name: "Tickets",
  //   component: () => import("../views/tickets/Tickets.vue"),
  //   children: [
  //     {
  //       path: "/tickets/view/:number/:companyId?",
  //       name: "Ticket",
  //       component: () => import("../views/tickets/Ticket.vue"),
  //     },
  //   ]
  // },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/kibo-sso",
    name: "KiboSso",
    component: () => import("../views/KiboSso.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/guestticket",
    name: "GuestTicket",
    component: () => import("../views/GuestTicket.vue"),
  },
  {
    path: "/resetpassword/:uuid",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/token",
    name: "Token",
    component: () => import("../views/Token.vue"),
  },
  {
    path: "/alerts",
    name: "Alerts",
    component: () => import("../views/Alerts.vue"),
  },
  {
    path: "/my-account",
    name: "MyAccount",
    component: () => import("../views/MyAccount.vue"),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("../views/Faq.vue"),
  },
  {
    path: "/resources",
    name: "Resources",
    component: () => import("../views/ResourcesPage.vue"),
  },
  ...(!!JSON.parse(import.meta.env.VITE_SHOW_SIGMA4COMPASS ?? false)
    ? [
        {
          path: "/sigma/:companyId?/:businessServiceId?/:deviceId?",
          name: "Sigma",
          component: () => import("../views/sigma/Sigma.vue"),
        },
      ]
    : []),
  // {
  //   path: "/change-requests/:companyId?",
  //   name: "ChangeRequests",
  //   component: () => import("../views/changeRequests/ChangeRequests.vue"),
  //   children: [
  //     {
  //       path: "/change-requests/view/:number/:companyId?",
  //       name: "ChangeRequest",
  //       component: () => import("../views/changeRequests/ChangeRequest.vue"),
  //     },
  //   ]
  // },
];
const router = createRouter({
  history: createWebHistory("/"),
  routes,
  scrollBehavior: async function (to) {
    if (to.hash) {
      for (let i = 0; i < 10; i++) {
        const element = document.querySelector(to.hash);
        if (element) {
          return { el: to.hash, behavior: "smooth", top: 84 };
        }
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      console.warn("Element with selector ${to.hash} not found after retries");
    } else {
      return { top: 0 };
    }
  },
});
export default router;
