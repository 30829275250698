import httpClient from "./httpClient";
import { AxiosRequestConfig } from "axios";

type DataDefault = Record<string, unknown>;

export const get = <TResponse = any>(api: string, config?: AxiosRequestConfig) => {
  return httpClient.get<TResponse>(`${import.meta.env.VITE_API_URL}/${api}`, config);
};

export const post = <TResponse = any, TData = DataDefault>(api: string, data?: TData, config?: AxiosRequestConfig) => {
  return httpClient.post<TResponse>(`${import.meta.env.VITE_API_URL}/${api}`, data, config);
};

export const put = <TResponse = any, TData = DataDefault>(api: string, data?: TData, config?: AxiosRequestConfig) => {
  return httpClient.put<TResponse>(`${import.meta.env.VITE_API_URL}/${api}`, data, config);
};

export const patch = <TResponse = any, TData = DataDefault>(api: string, data?: TData, config?: AxiosRequestConfig) => {
  return httpClient.patch<TResponse>(`${import.meta.env.VITE_API_URL}/${api}`, data, config);
};

export const Delete = <TResponse = any>(api: string, config?: AxiosRequestConfig) => {
  return httpClient.delete<TResponse>(`${import.meta.env.VITE_API_URL}/${api}`, config);
};
