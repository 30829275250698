export const COMPANIES = {
  CCL: "22e47a9ddb6836c028adbedffe961954",
  "OSS Test Child Account": "ff9b9aa8dbbbef08a3389693db961931",
  "Speedcast Demo": "1ff7ee381bdfe15443054009b04bcb31",
} as const;

export const LOCALES = ["en-US", "es"];

export const supportGroupAttachment = {
  GLOBAL: `305ecd381bfc521032e30d4cd34bcb81`,
  MARITIME: `3a1e85381bfc521032e30d4cd34bcb13`,
};

export const TEST_KIT_ID = "KITP00033470";
